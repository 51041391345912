.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.container .h1 {
    text-align: center;
}

.container .p {
    text-align: center;
}

.w-80 {
    width: 70%;
}

.slick-slider {
    padding-bottom: 70px;
}

/* .hero-slider-content  {
  padding-bottom: 15px;
} */
.twox {
    font-size: 30px;
    /* color: #389B4D !important; */
    color: #4F78CA !important;
}
.twox:hover {
    color: #1B2E4B !important;
}



.promo-section .single-promo-card img {
    max-width: 60%;
}
.laris-color{
    padding: 0.95rem 1.25rem;
    -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px 4px 0 0;
    /* background: linear-gradient(75deg, #C42F31,#ac9698) !important; */
    background: linear-gradient(75deg, #26476D, #6294f7) !important;
    margin-bottom: 0;
    border: 1px solid rgba(72, 94, 144, 0.16);
}
.faq-wrap .card .card-header.lariscollapsed{
    background: transparent;
}
.laris-color-primary {
    color: #C42F31 !important;
}
.web-color{
    padding: 0.95rem 1.25rem;
    -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px 4px 0 0;
    /* background: linear-gradient(75deg, #389B4D, #8b9b8b) !important; */
    background: linear-gradient(75deg, #26476D, #6294f7) !important;
    margin-bottom: 0;
    border: 1px solid rgba(72, 94, 144, 0.16);
}

.web-color-primary {
    color: #389B4D !important;
}
/* .price-table-wrapper .pricing-table thead td h2{
    color: #389B4D !important;

} */
.web{
    /* color: #389B4D !important; */
    color: #000 !important;

}
.accent-web-bg {
    /* background: #389B4D !important; */
    background: #1b2e4b !important;
  }
  .promo-one-single-web:hover {
    /* background: linear-gradient(75deg, #389B4D, #389B4D) !important; */
    /* background: #4F78CA !important; */
    background: linear-gradient(75deg, #143292, #4772CE) !important;
    color: #fff;
  }
  .promo-one-single-web-orange {
    /* background: orange !important; */
    background: linear-gradient(75deg, #143292, #4772CE) !important;
    color: #fff !important;
  }
 .promo-one-single-web-orange:hover {
    /* background: orange !important; */
    background: linear-gradient(75deg, #143292, #4772CE) !important;
    color: #fff !important;
  }

  .promo-one-single-web {
    position: relative;
    display: block;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  
  /* line 6, src/assets/scss/components/promo-section/_promo-1.scss */
  .promo-one-single-web a {
    text-decoration: none;
    position: absolute;
    right: 15px;
    bottom: -15px;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    -webkit-box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.12);
  }

  .laris{
    /* color: #C42F31 !important; */
    color: #000 !important;

}
.apt{
    color: #26476D !important;

}
.single-blog-card-laris .card-body a {
    font-size: 16px;
    font-weight: 600;
    color: #696969;
    font-family: "Montserrat", sans-serif;
  }
  
  /* line 46, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .card-body a:hover {
    color: #000;
  }
  
  /* line 50, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .card-body a.detail-link {
    display: inline-block;
    font-size: 14px;
  }
  
  /* line 53, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .card-body a.detail-link span {
    font-size: 12px;
    vertical-align: middle;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    color: #C42F31;
  }
  
  /* line 60, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .card-body a.detail-link:hover span {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
    margin-left: 8px;
  }
  .single-blog-card-laris .blog-img img {
    border-bottom: 3px solid #ec6e7b;
  }
  
  /* line 6, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .blog-img .meta-date {
    position: absolute;
    right: 20px;
    width: 55px;
    height: 55px;
    background: #ec6e7b;
    border: 3px solid #ec6e7b;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    bottom: -22px;
  }
  
  /* line 17, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .blog-img .meta-date strong {
    display: block;
    line-height: 4px;
    margin-top: 16px;
  }
  
  /* line 24, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .card-body {
    padding: 1.75rem;
  }
  
  /* line 26, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .card-body .post-meta {
    list-style: none;
    font-size: 0.8125rem;
    padding: 0;
    margin: 0;
  }
  
  /* line 31, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .card-body .post-meta .meta-list {
    opacity: 0.8;
    font-size: 95%;
  }
  
  /* line 35, src/assets/scss/components/blog-section/_blog-1.scss */
  .single-blog-card-laris .card-body .post-meta .meta-list li i {
    color: #0c2888;
  }


  .email-link {
    font-family: "Open Sans", sans-serif;
    font-size: 0.938rem;
    font-weight: 400;
    color: #696969 !important;
  }